<template>
  <section class="main" v-if="!isLoading" ref="element">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline" v-if="!isLoading">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Assessment Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Assessment</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `assessment` }">
                      <h3 class="is-size-6">Assessment Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name == 'createAssessment'"
                    >
                      Create Assessment
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>
                      Edit Assessment
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column bar">
              <b-field grouped position="is-right">
                <b-button class="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <AssessmentInformation :data="information" />
              </div>
              <div class="box">
                <AssessmentSettings :data="settings" />
              </div>
              <div class="box" v-if="isUseRules">
                <AssessmentRule :data="rule" />
              </div>

              <!-- For question use rules or not -->
              <b-field grouped position="is-right">
                <span style="padding-right: 10px">Use Rules</span>
                <b-switch type="is-hcc" v-model="isUseRules"></b-switch>
              </b-field>

              <div class="box">
                <AssessmentThankYouPage :data="thankYouPage" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import AssessmentInformation from '@/components/AssessmentForm/AssessmentInformation'
import AssessmentThankYouPage from '@/components/AssessmentForm/AssessmentThankYouPage'
import AssessmentRule from '@/components/AssessmentForm/AssessmentRule'
import AssessmentSettings from '@/components/AssessmentForm/AssessmentSettings'

export default {
  components: {
    AssessmentInformation,
    AssessmentThankYouPage,
    AssessmentRule,
    AssessmentSettings,
  },
  name: 'create-assessment',
  data() {
    return {
      // For Loading Page
      isLoading: false,

      // For Information Data
      information: {
        title: '',
        description: '',
        status: 'Active',
        category: [],
      },

      // For Thank You Page Data
      thankYouPage: {
        title: '',
        description: '',
      },

      rule: {
        endDate: '',
        startDate: '',
      },

      settings: {
        screenCaptureTime: '',
        screenShotTime: '',
      },

      isUseRules: true,

      isEditing: false,
    }
  },
  watch: {},
  created() {
    if (this.$route.name == 'editAssessment') {
      this.fetchData()
    } else {
      this.isLoading = false
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      const response = await this.$store.dispatch(
        'getAssessment',
        this.$route.params.assessmentId
      )
      this.thankYouPage = response.data.thankYouPage
      this.information = response.data.information
      this.rule = response.data.rule
      this.settings = response.data.settings
      this.isUseRules = response.data.isUseRules
      this.rule.endDate =
        this.rule.endDate == null ? '' : new Date(this.rule.endDate)
      this.rule.startDate =
        this.rule.startDate == null ? '' : new Date(this.rule.startDate)
      this.isLoading = false
    },
    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: 'Save Assessment',
        message: `Are you sure want to save this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, save it!',
        type: 'is-hcc',
        onConfirm: () => this.createAssessment(),
      })
    },

    // For store assessment
    async createAssessment() {
      if (this.$route.name != 'editAssessment') {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el,
        })
        await this.$store
          .dispatch('createAssessment', {
            // For send data of information
            pageTitle: this.information.title,
            pageDescription: this.information.description,
            category: this.information.category[0],
            status: this.information.status,
            rules: this.rule.rules,
            thankYouPageTitle: this.thankYouPage.title,
            thankYouPageDescription: this.thankYouPage.description,
            screenCaptureTime: this.settings.screenCaptureTime,
            screenShotTime: this.settings.screenShotTime,
            isUseRules: this.isUseRules,
            startDate: this.rule.startDate,
            endDate: this.rule.endDate,
          })
          .then(response => {
            if (response.data.status == 200) {
              this.isEditing = true
              this.success('Successfully create assessment')
              loadingComponent.close()
              this.$router.push({ name: 'assessment' })
            } else {
              this.danger('failed to create a new assessment')
            }
          })
          .catch(() => {
            this.danger('Failed to create a new assessment')
            loadingComponent.close()
          })
      } else {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el,
        })
        await this.$store
          .dispatch('editAssessment', {
            // For send data of information
            pageTitle: this.information.title,
            pageDescription: this.information.description,
            category: this.information.category[0],
            status: this.information.status,

            thankYouPageTitle: this.thankYouPage.title,
            thankYouPageDescription: this.thankYouPage.description,
            id: this.$route.params.assessmentId,
            screenCaptureTime: this.settings.screenCaptureTime,
            screenShotTime: this.settings.screenShotTime,

            isUseRules: this.isUseRules,

            startDate: this.rule.startDate,
            endDate: this.rule.endDate,
          })
          .then(() => {
            this.isEditing = true
            this.success('Successfully create assessment')
            loadingComponent.close()
            this.$router.push({ name: 'assessment' })
          })
          .catch(() => {
            this.danger('Failed to create a new assessment')
            loadingComponent.close()
          })
      }
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing == false) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      )
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
}
</script>
<style>
.prev {
  margin-left: 1.1% !important;
}
.button-next {
  margin-right: 1% !important;
}
</style>
