<template>
  <div>
    <h1 class="title has-text-centered">Settings</h1>
    <div class="box">
      <!-- For screen capture time -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Interval Screen Capture</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns is-12">
            <div class="column is-10">
              <ValidationProvider
                :vid="`screencapturetime`"
                :rules="`required|minsecond:1`"
                name="Screen Capture Time"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-input
                    v-model="data.screenCaptureTime"
                    type="number"
                    min="1"
                  ></b-input>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="column is-2">Seconds</div>
          </div>
        </div>
      </div>

      <!-- For screen record time -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Interval take candidate's photo</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns is-12">
            <div class="column is-10">
              <ValidationProvider
                :vid="`screenshottime`"
                :rules="`required|minsecond:1`"
                name="Screen Shot Time"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-input
                    v-model="data.screenShotTime"
                    type="number"
                    min="1"
                  ></b-input>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="column is-2">Seconds</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
}
</script>
