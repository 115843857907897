<template>
  <div>
    <h1 class="title has-text-centered">Rules</h1>
    <div class="box">
      <div class="columns is-12">
        <div class="column is-2">
          <b>Periode</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <span class="columns">
            <span class="column is-6">
              <ValidationProvider
                :vid="`startDate`"
                name="Start Date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-datetimepicker
                    placeholder="Select start time..."
                    v-model="data.startDate"
                    :max-datetime="data.endDate"
                  ></b-datetimepicker>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span class="column is-6">
              <ValidationProvider
                :vid="`endDate`"
                name="End Date"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-datetimepicker
                    placeholder="Select end time..."
                    v-model="data.endDate"
                    :min-datetime="data.startDate"
                  ></b-datetimepicker>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
}
</script>
